@tailwind base;

@import "../../node_modules/swiper/css/swiper.min.css";
@import "../../node_modules/lightbox2/dist/css/lightbox.min.css";
@import "../../node_modules/dropzone/dist/dropzone.css";

@tailwind utilities;
@tailwind components;
@tailwind screens;

/* raleway-regular - latin */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  src: url('https://cdn-fonts.backslash-n.io/raleway/raleway-v28-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('https://cdn-fonts.backslash-n.io/raleway/raleway-v28-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('https://cdn-fonts.backslash-n.io/raleway/raleway-v28-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('https://cdn-fonts.backslash-n.io/raleway/raleway-v28-latin-regular.woff') format('woff'), /* Modern Browsers */
  url('https://cdn-fonts.backslash-n.io/raleway/raleway-v28-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('https://cdn-fonts.backslash-n.io/raleway/raleway-v28-latin-regular.svg#Raleway') format('svg'); /* Legacy iOS */
}
/* raleway-500 - latin */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500;
  src: url('https://cdn-fonts.backslash-n.io/raleway/raleway-v28-latin-500.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('https://cdn-fonts.backslash-n.io/raleway/raleway-v28-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('https://cdn-fonts.backslash-n.io/raleway/raleway-v28-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
  url('https://cdn-fonts.backslash-n.io/raleway/raleway-v28-latin-500.woff') format('woff'), /* Modern Browsers */
  url('https://cdn-fonts.backslash-n.io/raleway/raleway-v28-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
  url('https://cdn-fonts.backslash-n.io/raleway/raleway-v28-latin-500.svg#Raleway') format('svg'); /* Legacy iOS */
}
/* raleway-600 - latin */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 600;
  src: url('https://cdn-fonts.backslash-n.io/raleway/raleway-v28-latin-600.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('https://cdn-fonts.backslash-n.io/raleway/raleway-v28-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('https://cdn-fonts.backslash-n.io/raleway/raleway-v28-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
  url('https://cdn-fonts.backslash-n.io/raleway/raleway-v28-latin-600.woff') format('woff'), /* Modern Browsers */
  url('https://cdn-fonts.backslash-n.io/raleway/raleway-v28-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
  url('https://cdn-fonts.backslash-n.io/raleway/raleway-v28-latin-600.svg#Raleway') format('svg'); /* Legacy iOS */
}
/* raleway-700 - latin */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  src: url('https://cdn-fonts.backslash-n.io/raleway/raleway-v28-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('https://cdn-fonts.backslash-n.io/raleway/raleway-v28-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('https://cdn-fonts.backslash-n.io/raleway/raleway-v28-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
  url('https://cdn-fonts.backslash-n.io/raleway/raleway-v28-latin-700.woff') format('woff'), /* Modern Browsers */
  url('https://cdn-fonts.backslash-n.io/raleway/raleway-v28-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
  url('https://cdn-fonts.backslash-n.io/raleway/raleway-v28-latin-700.svg#Raleway') format('svg'); /* Legacy iOS */
}
/* raleway-italic - latin */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 400;
  src: url('https://cdn-fonts.backslash-n.io/raleway/raleway-v28-latin-italic.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('https://cdn-fonts.backslash-n.io/raleway/raleway-v28-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('https://cdn-fonts.backslash-n.io/raleway/raleway-v28-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('https://cdn-fonts.backslash-n.io/raleway/raleway-v28-latin-italic.woff') format('woff'), /* Modern Browsers */
  url('https://cdn-fonts.backslash-n.io/raleway/raleway-v28-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('https://cdn-fonts.backslash-n.io/raleway/raleway-v28-latin-italic.svg#Raleway') format('svg'); /* Legacy iOS */
}

html {
  @apply h-full;
}

h1, h2 {
  font-weight: 900 !important;
}

p {
  @apply text-justify;
}

body {
  background-color: #fff;

  &.debug {

    section {
      border-top: 5px dashed green;
      border-bottom: 5px dashed blue;
    }

  }
}

.header {

  &-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto auto;
    grid-row-gap: 10px;
    grid-column-gap: 10px;

    grid-template-areas: "logo logo burgerbutton" "logo logo burgerbutton";

    @media (min-width: 640px) {
      grid-template-areas:
        "logo search burgerbutton"
        "logo search burgerbutton";
    }

    @media (min-width: 768px) {
      grid-template-areas:
        "logo search search"
        "nav nav nav";
    }

    @media (min-width: 1024px) {
      grid-template-areas:
        "logo search search"
        "logo nav nav";
    }

  }

  &-logo {
    grid-area: logo;
    justify-self: start;
    align-self: center;
  }

  &-nav {
    grid-area: nav;
    justify-self: end;
    align-self: center;

    li:hover {

      > div {
        display: flex;
      }

    }
  }

  &-search {
    grid-area: search;
    justify-self: end;
    align-self: center;
  }

  &-burger-button {
    grid-area: burgerbutton;
    justify-self: end;
  }

}

.form-input {
  @apply col-span-8 w-full appearance-none block text-gray-700 border border-ci-red py-3 px-4 leading-tight;

  &::placeholder {
    @apply text-gray-700;
  }

  /** wtf, min-w-0 isnt working */
  min-width: 0;

  &:focus {
    @apply outline-none bg-white border-ci-darker-red;
  }

  option:checked {
    background: #b2001a -webkit-linear-gradient(bottom, #b2001a 0%, #b2001a 100%);
  }

  &-big {
    @apply col-span-10;
  }

  &-gewicht {
    @apply col-span-12;
  }

}

.form-text {
  @apply w-auto text-lg leading-tight py-3 font-bold col-span-4;

  &-main {
    @apply col-span-2;
  }

  &-small {
    @apply text-sm font-normal col-span-2;
  }

  &-big {
    @apply col-span-12;
  }

}

.form-label {
  @apply mb-4 grid grid-cols-12 gap-4;

  &-checkbox {
    @apply flex items-center gap-0;
  }

  &-range {
    @apply col-span-6;
  }

}

.form-section-headline {
  @apply text-xl text-ci-red uppercase mb-1;
}

.form-range-wrapper {
  @apply grid grid-cols-12 gap-4;
}

.form-checkbox {
  @apply mr-2;

}

@screen sm {
  .form-label-range,
  .form-text-big {
    @apply col-span-4;
  }

  .form-input-gewicht {
    @apply col-span-8;
  }
}

.hover\:children-div-opacity-100:hover {

  &
  > div {
    opacity: 100;
  }

}

.hover\:marked-children-opacity-100:hover {

  .children-to-show {
    opacity: 100;
  }

}

.wysiwyg {

  ul {
    @apply list-outside ml-4 list-disc;
  }

  ol {
    @apply list-outside ml-4 list-decimal;
  }

  a {
    @apply text-ci-red;

    &:hover {
      @apply underline;
    }

  }

  @apply table-auto;

  table {

    td, th {
      @apply border px-4 py-2;
    }

  }

}

@screen sm {
  .wysiwyg {
    overflow-x: inherit;
  }
}

.headline-wrapper {

  .headline {
    @apply p-0 mb-4;
  }

}

.video-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 0;
  height: 0;
  overflow: hidden;
  width: 100%;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

}

.keep-line-height:before {
  content: "\200b";
}

.swiper-pagination-bullet {
  height: 10px;
  width: 10px;
}

.swiper-pagination-bullet-active {
  @apply bg-ci-red;
}

.swiper-pagination-rating {
  @apply relative;
  bottom: auto !important;

  .swiper-pagination-bullet {
    @apply bg-ci-light-gray;
  }

  .swiper-pagination-bullet-active {
    @apply bg-white;
  }

}

.webinar {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;


  &-nav {
    grid-area: nav;
    align-self: center;
  }

  &-social-media {
    grid-area: social-media;
    align-self: start;
  }

  &-countdown {
    grid-area: countdown;
  }

  &-description {
    grid-area: description;
  }

  grid-template-areas:
    "social-media nav"
    "countdown countdown"
    "description description";

  @media (min-width: 1024px) {
    grid-template-areas:
      "social-media nav"
      "description countdown";
  }

}

#countdown {

  .simply-section {

    div {
      @apply flex flex-col
    }

    .simply-amount {
      @apply text-2xl
    }

    .simply-word {

    }

  }
}

label.required:after {
  content: " *";
}

picture {

  img {
    @apply w-full
  }

}

.formbuilder {
  @apply flex flex-col container mx-auto px-5 max-w-screen-md;
}

.form-group {
  @apply grid grid-cols-3 py-2;
}

.form-control {
}

.btn {
  @apply bg-ci-red text-white py-1;
}

#denmark_form_overflade label:nth-child(2) {
  margin-right: 1rem;
}
#lakeret_ral_input label {
  width: 4rem;
  flex-shrink: 0;
}
#denmark_form_radio_group label::after {
  display: block;
}